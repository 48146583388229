import React, { Component } from "react"
import { Link } from "gatsby"
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa"
import { FiX, FiMenu } from "react-icons/fi"

const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/",
    name: "facebook",
  },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/",
    name: "linkedin",
  },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/",
    name: "instagram",
  },
  { Social: <FaTwitter />, link: "https://twitter.com/", name: "twitter" },
]

class Header extends Component {
  constructor(props) {
    super(props)
    this.menuTrigger = this.menuTrigger.bind(this)
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this)
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open")
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open")
  }

  render() {
    const { color = "default-color" } = this.props
    let logoUrl = (
      <img
        src="/assets/images/logo/whittyshield.png"
        width="75"
        alt="Digital Agency"
      />
    )
    // if(logo === 'light'){
    //     logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
    // }else if(logo === 'dark'){
    //     logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
    // }else if(logo === 'symbol-dark'){
    //     logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
    // }else if(logo === 'symbol-light'){
    //     logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
    // }else{
    //     logoUrl = <img src="/assets/images/logo/whittyshield.png" alt="Digital Agency" />;
    // }

    return (
      <header
        className={`header-area header-style-two header--transparent ${color}`}
      >
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
            <nav className="mainmenunav d-block ml--50">
              <ul className="mainmenu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/leadership-development">Leadership Development</Link>
                </li>
                {/* <li className="has-droupdown">
                      <Link to="#">Leadership Consulting</Link>
                      <ul className="submenu">
                        <li>
                          <Link to="/portfolio">Portfolio</Link>
                        </li>
                        <li>
                          <Link to="/team">Team</Link>
                        </li>
                        <li>
                          <Link to="/service">Service</Link>
                        </li>
                        <li>
                          <Link to="/video-popup">Video Popup</Link>
                        </li>
                        <li>
                          <Link to="/progressbar">Progressbar</Link>
                        </li>
                        <li>
                          <Link to="/gallery">Gallery</Link>
                        </li>
                        <li>
                          <Link to="/counters">Counters</Link>
                        </li>
                        <li>
                          <Link to="/blog">Blog List</Link>
                        </li>
                        <li>
                          <Link to="/clint-logo">Clint Logo</Link>
                        </li>
                        <li>
                          <Link to="/contact-form">Contact Form</Link>
                        </li>
                        <li>
                          <Link to="/google-map">Google Map</Link>
                        </li>
                        <li>
                          <Link to="/columns">Columns</Link>
                        </li>
                        <li>
                          <Link to="/pricing-table">Pricing Table</Link>
                        </li>
                      </ul>
                    </li> */}
                <li>
                  <Link to="/executive-coaching">Executive Coaching</Link>
                </li>
                {/* <li className="has-droupdown">
                      <Link to="#">Executive Coaching</Link>
                      <ul className="submenu">
                        <li>
                          <Link to="/blog">Blog List</Link>
                        </li>
                        <li>
                          <Link to="/blog-details">Blog Details</Link>
                        </li>
                        <li>
                          <Link to="/service">Service</Link>
                        </li>
                        <li>
                          <Link to="/service-details">Service Details</Link>
                        </li>
                        <li>
                          <Link to="/portfolio">Portfolio</Link>
                        </li>
                        <li>
                          <Link to="/portfolio-details">Portfolio Details</Link>
                        </li>
                        <li>
                          <Link to="/404">404</Link>
                        </li>
                      </ul>
                    </li> */}
                <li>
                  <Link to="/who-is-john-mercer">Who Is John Mercer?</Link>
                </li>
                <li>
                  <Link to="/journal">Journal</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header-right">
            <div className="social-share-inner">
              <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                {this.props.socialMedia.map((val, i) => {
                  const social = SocialShare.find(soc => {
                    return soc.name === val.socialSite.toLowerCase()
                  })

                  return (
                    <li key={i}>
                      <a href={`${val.url}`}>{social.Social}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="header-btn d-none d-md-block">
              <a className="rn-btn" href="/contact">
                <span>Get Started</span>
              </a>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
export default Header
